import { Component, Vue } from "vue-property-decorator";
import { googleRecaptchaModel } from "../../model";
// import getGrecaptcha from "../../getGrecaptcha";

@Component
export default class GoogleRecaptcha extends Vue {
  private async mounted() {
    // const grecaptcha = await getGrecaptcha();

    // grecaptcha.render(this.$el as HTMLElement, {
    //   sitekey: googleRecaptchaModel.siteKey,
    // });

    console.log("googleRecaptchaModel", googleRecaptchaModel.siteKey);
  }
}
